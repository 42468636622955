.root {
  .title {
    display: block;
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
    unicode-bidi: isolate;
  }
}

.materialTitle {
  margin: 0;
}
