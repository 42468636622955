@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(16, 400, 1.37, #fff);
  margin: 0 0 48px 0;
  padding: 0 6px 0 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  min-height: 88px;
  max-width: 290px;

  @media screen and (max-width: 1439px) {
    max-width: 405px;
  }

  @media screen and (max-width: 1049px) {
    margin-bottom: 66px;
    padding-right: 0;
  }

  @media screen and (max-width: 667px) {
    margin-bottom: 34px;
    font-size: 12px;
    line-height: 1.33;
    -webkit-line-clamp: 6;
    min-height: 96px;
  }
}

.rootTypeFullAdvert {
  line-height: 1.375;
  max-width: 761px;
  margin-bottom: 0;
  margin-left: 17px;
  padding-right: 0;
  min-height: 132px;
  display: block;
  overflow: visible;
  text-overflow: clip;
  -webkit-line-clamp: initial;

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.rootTypeFullText {
  display: block;
  overflow: visible;
  text-overflow: clip;
  -webkit-line-clamp: initial;
}

p {
  margin: 10px 0 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  a {
    color: #fff;
  }
}

.link {
  color: #fff;
}
