html {
  scroll-behavior: smooth;
}

.page {
  background-color: #fff;
  color: #000;
  font-family: 'Manrope', 'Helvetica Neue', 'Arial', sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

.content {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home {
  display: flex;
  flex-direction: column;
}
