.root {
  margin-top: 6px;

  @media (hover: hover) {
    &:not(.rootTypeBrandBanner):hover {
      .image {
        box-shadow: 0px 0px 20px 0px #ffbb4d;
      }
    }
  }
}

// .rootTypeAdmin {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.rootTypeBrandBanner {
  grid-row: 1/4;
  grid-column: 2/3;
  margin-left: auto;
  margin-top: 0;
  position: relative;
  font-size: 0;

  &::after {
    content: '';
    width: 1440px;
    height: 928px;
    position: absolute;
    top: -111px;
    left: -213px;
    transform: translateX(-50%);
    background-image: url('../../assets/backgrounds/brand-banner-background.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @media screen and (max-width: 1193px) {
      width: 1024px;
      height: 660px;
      top: -73px;
      left: -144px;
    }

    @media screen and (max-width: 667px) {
      width: 864.19px;
      height: 557px;
      top: -166px;
      left: 90px;
      background-image: url('../../assets/backgrounds/brand-banner-background-image-small.svg');
    }
  }

  @media screen and (max-width: 944px) {
    grid-row: 2/4;
  }

  @media screen and (max-width: 667px) {
    margin-right: auto;
  }

  @media screen and (max-width: 566px) {
    margin-top: 70px;
  }
}

.image {
  width: 77.7px;
  height: 77.7px;
  background-color: #ffbb4d;
  object-fit: cover;
  object-position: left;
  border-radius: 50%;
  padding: 5.66px;
  font-size: 0;
  display: inline-block;
  transition: box-shadow 0.3s ease-in-out;
}

.imageTypeBrandBunner {
  width: 377px;
  height: 383px;
  padding: 0;
  background-color: transparent;
  border-radius: 0%;
  object-fit: fill;
  object-position: 50% 50%;
  z-index: 2;

  @media screen and (max-width: 1193px) {
    width: 277px;
    height: 282px;
  }

  @media screen and (max-width: 667px) {
    width: 180px;
    height: 181px;
  }
}
